import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/common-config/report-heading/detail', orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('grantDistribution.applicant_id'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
            { text: vm.$t('grantDistribution.applicant_name'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
            { text: vm.$t('grantDistribution.organization'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
            { text: vm.$t('BarcReport.email'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
            { text: vm.$t('BarcReport.mobile_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
            { text: vm.$t('grantDistribution.fund_sub_head'), colSpan: 4, style: 'th', alignment: 'center', bold: true },
            {},
            {},
            {},
            { text: vm.$t('BarcReport.remark'), style: 'th', rowSpan: 2, alignment: 'center', bold: true }
          ],
          [
            {},
            {},
            {},
            {},
            {},
            { text: vm.$t('BarcReport.admission'), style: 'th1', alignment: 'right' },
            { text: vm.$t('BarcReport.stipend'), style: 'th1', alignment: 'right' },
            { text: vm.$t('grantDistribution.applicant_name'), style: 'th1', alignment: 'right' },
            { text: vm.$t('grantDistribution.organization'), style: 'th1', alignment: 'right' },
            {}
          ]
        ]
        newDatas.map((info, index) => {
          // allRows.push([
          //   { text: vm.$n(info.applicant_id), alignment: 'center', style: 'td' },
          //   { text: (i18n.locale === 'bn') ? info.data.name_bn : info.data.name, alignment: 'center', style: 'td' },
          //   { text: vm.$n(info.can_org_id), alignment: 'center', style: 'td' },
          //   { text: (info.email), alignment: 'center', style: 'td' },
          //   { text: vm.$n(info.mobile_no, { useGrouping: false }), alignment: 'center', style: 'td' },
          //   { text: vm.$n(info.due_amount, { useGrouping: false }), alignment: 'center', style: 'td' },
          //   { text: vm.$n(info.due_amount, { useGrouping: false }), alignment: 'center', style: 'td' },
          //   { text: vm.$n(info.due_amount, { useGrouping: false }), alignment: 'center', style: 'td' },
          //   { text: vm.$n(info.due_amount, { useGrouping: false }), alignment: 'center', style: 'td' },
          //   { text: (i18n.locale === 'bn') ? info.data.remark_bn : info.data.remark, alignment: 'center', style: 'td' }
          // ])
          allRows.push([
            { text: vm.$n(info.applicant_id), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.data.name_bn : info.data.name, alignment: 'center', style: 'td' },
            { text: vm.$n(info.can_org_id), alignment: 'center', style: 'td' },
            { text: (info.email), alignment: 'center', style: 'td' },
            { text: vm.$n(info.mobile_no, { useGrouping: false }), alignment: 'center', style: 'td' },
            {},
            {},
            {},
            {},
            { text: (i18n.locale === 'bn') ? info.data.remark_bn : info.data.remark, alignment: 'center', style: 'td' }
           ])
           info.fundData.forEach((fund, index) => {
            allRows.push([
              { text: vm.$n(fund.due_amount, { useGrouping: false }), alignment: 'center', style: 'td' }
            ])
          })
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          th1: {
            fontSize: 9,
            marginLeft: 40
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('due-applicant-funding-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
