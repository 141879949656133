<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('BarcReport.dues_report_of_applicant_funding') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                         <b-col lg="6" sm="12">
                          <ValidationProvider name="Fund Sub Head" vid="fund_sub_head_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fund_sub_head_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('grantDistribution.fund_sub_head') }}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fund_sub_head_id"
                                :options="subHeadList"
                                id="fund_sub_head_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Date" vid="start_date">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="start_date"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('globalTrans.from_date')}}
                              </template>
                                  <b-form-input
                                  class="datepicker"
                                  v-model="formData.start_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  placeholder="yyyy-mm-dd"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Date" vid="end_date">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="end_date"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('globalTrans.end_date')}}
                              </template>
                                  <b-form-input
                                  class="datepicker"
                                  v-model="formData.end_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  placeholder="yyyy-mm-dd"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search') }}</b-button>
                        </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('BarcReport.dues_report_of_applicant_funding_list') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                            {{  $t('globalTrans.export_pdf') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="4">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('BarcReport.dues_report_of_applicant_funding') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                                        <b-thead>
                                          <b-tr>
                                            <b-th rowspan="2" style="width:10%" class="text-center">{{ $t('grantDistribution.applicant_id') }}</b-th>
                                            <b-th rowspan="2" style="width:10%" class="text-center">{{ $t('grantDistribution.applicant_name') }}</b-th>
                                            <b-th rowspan="2" style="width:10%" class="text-center">{{ $t('grantDistribution.organization') }}</b-th>
                                            <b-th rowspan="2" style="width:15%" class="text-center">{{ $t('BarcReport.email') }}</b-th>
                                            <b-th rowspan="2" style="width:13%" class="text-center">{{ $t('BarcReport.mobile_no') }}</b-th>
                                            <b-th colspan="4" style="width:10%" class="text-center">{{ $t('grantDistribution.fund_sub_head') }}</b-th>
                                            <b-th rowspan="2" style="width:10%" class="text-center">{{ $t('BarcReport.remark') }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                            <b-th style="width:10%" class="text-center" v-for="(subHead, indx) in subHeadList" :key="indx">{{ subHead.text }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in datas" :key="index">
                                          <b-td class="text-center">{{ $n(info.applicant_id)}}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_bn : info.name}}</b-td>
                                          <b-td class="text-center">{{ getOrganizationName(info.can_org_type, info.can_org_id) }}</b-td>
                                          <b-td class="text-center">{{ info.email }}</b-td>
                                          <b-td class="text-center">{{ $i18n.locale === 'bn' ? '০' : '0' + $n(info.mobile_no ,{ useGrouping: false }) }}</b-td>
                                          <slot v-for="fundData in info.fund">
                                            <b-td class="text-center">{{ fundData.due_amount }}</b-td>
                                          </slot>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.remark : info.remark_bn}}</b-td>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { dueApplicantFundReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
      ValidationObserver,
      ValidationProvider,
      ListReportHead
    },
    created () {
    },
    data () {
        return {
          formData: {
            start_date: '',
            end_date: '',
            fund_sub_head_id: 0
          },
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          loading: false,
          showData: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        this.headerDataExcel()
    },
    computed: {
      subHeadList: function () {
        const headlist = this.$store.state.incentiveGrant.commonObj.fundSubHeadList.filter(item => item.status === 1)
        return headlist.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
          }
        })
      },
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('BarcReport.grant_application_status_report')
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('BarcReport.grant_application_status_report')
        }
        return headerVal
      },
      excelData: function () {
        const listData = this.datas
        var serial = 0
        const listContractor = listData.map(keyItem => {
          serial++
            if (this.$i18n.locale === 'en') {
              return {
                'sl ': serial,
                'Applicants ID': keyItem.applicant_id,
                'Applicants Name': keyItem.name,
                'Organization ': keyItem,
                'Fathers Name': keyItem.father_name,
                'Email ': keyItem.email,
                'Mobile Number': keyItem.mobile_no,
                'Field Of Study ': keyItem.field_of_study,
                'Totol Score': '',
                'Approval Reject Status': keyItem.status,
                'Approval Reject Date': ''
              }
            } else {
              return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'আবেদনকারীদের আইডি ': keyItem.applicant_id,
                'আবেদনকারীর নাম': keyItem.name_bn,
                'আবেদনকারীর তারিখ': '',
                'পিতার নাম': keyItem.father_name_bn,
                'জন্ম তারিখ': keyItem.date_of_birth,
                'এনআইডি ': keyItem.nid,
                'ইমেল ': keyItem.email,
                'মোবাইল নম্বর': keyItem.mobile_no,
                'অধ্যয়নের ক্ষেত্র ': keyItem.field_of_study_bn,
                'সম্পূর্ণ ফলাফল': '',
                'অনুমোদনের প্রত্যাখ্যানের স্থিতি': keyItem.status,
                'অনুমোদনের প্রত্যাখ্যানের তারিখ': ''
              }
            }
        })
        return listContractor
      }
    },
    watch: {
    },
    methods: {
      headerDataExcel () {
        RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/4').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 4)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
      pdfExport () {
          const reportTitle = this.$t('BarcReport.grant_application_status_report')
          ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 4, reportTitle, this.datas, this, this.formData)
      },
      async searchData () {
      this.loading = true
      this.showData = true
      const params = Object.assign({}, this.formData)
        await RestApi.getData(incentiveGrantServiceBaseUrl, dueApplicantFundReport, params).then(response => {
          if (response.success) {
            this.loading = false
            if (response.data) {
              this.datas = response.data
            }
          } else {
            this.datas = []
            this.loading = false
          }
        })
      },
      getRelationalData (data) {
        const orgList = this.$store.state.commonObj.organizationProfileList
        const listData = data.map(item => {
          const orgData = orgList.find(organization => organization.value === item.can_org_id)
              const orgObj = {
                organization_en: orgData !== undefined ? orgData.text_en : '',
                organization_bn: orgData !== undefined ? orgData.text_bn : ''
              }
              return Object.assign({}, item, orgObj)
          })
        return listData
     },
     getOrganizationName (orgType, orgId) {
      this.loading = false
      if (orgType === 2) {
        const org = this.$store.state.incentiveGrant.commonObj.narseInstituteList.find(item => item.value === orgId)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      } else {
        const org = this.$store.state.orgList.find(item => item.value === orgId)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      }
    }
  }
}
</script>
